$oyga-red: #f40238;

.Presentation-box {
  background: #fff;
  border: solid 1px rgba(#000, 0.1);
  overflow: hidden;
  position: relative;

  .box-background {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.spectacle-content {
  padding: 0 !important;
}

// Fix to some weird bug of spectacle for printing
.pdf-slides .spectacle-slide {
  position: relative !important;
}

.mrc--text {
  i {
    font-style: normal;
    color: $oyga-red;
  }
}

.box-list {
  .box-list-item {
    padding-left: 24px;
    margin-bottom: 24px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -2px;
      top: 6px;
      height: 12px;
      width: 12px;
      border-radius: 100%;
      border: solid 1px $oyga-red;
      opacity: 0.5;
    }
  }
}

.tile.is-child {
  @extend .Presentation-box;
  padding: 16px;
}

.CoverSlide {
  .CoverSlide-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    filter: grayscale(100%) contrast(1.5) opacity(0.15);
    object-fit: cover;
  }

  .columns {
    position: relative;
  }

  .CoverSlide-logo {
    max-width: 90%;
  }

  .CoverSlide-box {
    background: #fff;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: -100%;
      top: 0;
      background: #fff;
    }
  }

  .column {
    text-align: left;
  }
}

.MetricSummary {
  @extend .Presentation-box;
  padding: 24px 16px 0 16px;
  height: 700px;

  display: flex;
  flex-direction: column;

  .MS_part {
    flex-grow: 1;

    &.msp_chart {
      margin-left: -32px;
      margin-right: -32px;
    }
  }

  .mrc--counter-label {
    margin-top: 1.25rem !important;
    opacity: 0.8;
  }

  .mrc--counter-body {
    justify-content: center;
  }

  .mrc--status {
    flex-direction: column;
    margin: 2rem 0 0.5rem 0;

    .trend-label {
      display: flex;
      margin: 1rem 0 0 0;
      font-size: 1.5rem;

      .trend-text {
        display: none;
      }
    }
  }
}

.recharts-legend-item {
  .recharts-legend-item-text {
    font-size: 12px;
    color: #2b2024;
    display: inline-block;
  }
}

.TopAdBox,
.AgeChartBox {
  @extend .Presentation-box;
  padding: 24px;
  min-height: 650px;
}

.AgeChartBox {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.FunnelSummary {
  display: flex;
  flex-direction: column;

  min-height: 500px;

  .FS_part {
    flex-basis: 1;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 150px;
  }
}
